class FormFiles{

    constructor(options) {
        this.events();
    }

    events() {
        document.addEventListener('click', function(e){
            this.element = e.target;

            // placeholder
            this.placeholder_container = this.element.closest('.placeholder-container');
            if (this.placeholder_container) this.placeholder();

            // mask
            if (this.element.dataset.mask) this.mask();


            // upload_files
            this.custom_file = this.element.closest('.custom-file');
            if (this.custom_file) {
                this.custom_file.addEventListener('change', () => {
                    this.uploadFiles();
                });
            }

            // btn
            if (this.element.dataset.form) {
                e.preventDefault();
                this.form = this.element.closest('form');
                this.validate();
            }

        }.bind(this));

    }

    validate() {
        this.necessarily = this.form.querySelectorAll('._necessarily');
        this.error = 0;

        let addError = input => {
            input.classList.add('error');
            input.style.border = '1px solid red';
            this.error++;
        }

        let removeError = input => {
            input.classList.remove('error');
            input.style.border = '';
        }

        this.necessarily.forEach((item) => {
            removeError(item)
            if (item.value === '') addError(item);
            if (item.dataset.value === '0') addError(item);
        });

        if(this.error === 0) this.getData()

    }

    mask() {
        let maskInput = this.element.dataset.mask;

        let getInputNumbersValue = input => input.value.replace(/\D/g, '');

        if(maskInput === 'tel') {

            let onInput = function (e) {
                let input = e.target;
                let	inputNumbersValue = getInputNumbersValue(input);
                let	selectionStart = input.selectionStart;
                let	formattedInputValue = "";

                if (input.value.length != selectionStart) {
                    if (e.data && /\D/g.test(e.data)) {
                        input.value = inputValue;
                    }
                    return;
                }

                var firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
                formattedInputValue = input.value = firstSymbols + " ";
                if (inputNumbersValue.length > 1) {
                    formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
                }
                if (inputNumbersValue.length >= 5) {
                    formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
                }
                if (inputNumbersValue.length >= 8) {
                    formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
                }
                if (inputNumbersValue.length >= 10) {
                    formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
                }





                input.value = formattedInputValue;
            }

            let onPhonePaste = function (e) {
                var input = e.target,
                    inputNumbersValue = getInputNumbersValue(input);
                var pasted = e.clipboardData || window.clipboardData;
                if (pasted) {
                    var pastedText = pasted.getData('Text');
                    if (/\D/g.test(pastedText)) {
                        // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
                        // formatting will be in onPhoneInput handler
                        input.value = inputNumbersValue;
                        return;
                    }
                }
            }

            let onPhoneKeyDown = function (e) {
                // Clear input after remove last symbol
                var inputValue = e.target.value.replace(/\D/g, '');
                if (e.keyCode == 8 && inputValue.length == 1) {
                    e.target.value = "";
                }
            }

            this.element.addEventListener('keydown', onPhoneKeyDown);
            this.element.addEventListener('input', onInput, false);
            this.element.addEventListener('paste', onPhonePaste, false);
        }

        if(maskInput === 'date') {
            let onInput = function (e) {
                let input = e.target;
                let	inputValue = getInputNumbersValue(input);
                let formattedInputValue = '';
                let	selectionStart = input.selectionStart;

                if (input.value.length != selectionStart) {
                    if (e.data && /\D/g.test(e.data)) input.value = inputValue;
                    return;
                }

                if (inputValue.length > 0 && parseInt(inputValue[0]) < 4) formattedInputValue += inputValue.substring(0, 1);
                if (inputValue.length >= 2) formattedInputValue += inputValue.substring(1, 2);
                if (inputValue.length >= 3 && parseInt(inputValue[2]) < 2) formattedInputValue += '.' + inputValue.substring(2, 3);
                if (inputValue.length >= 4) formattedInputValue += inputValue.substring(3, 4);
                if (inputValue.length >= 5) formattedInputValue += '.' + inputValue.substring(4, 6);
                input.value = formattedInputValue;
            }
            this.element.addEventListener('input', onInput, false);
        }



    }

    placeholder() {
        this.placeholder_container.querySelector('.caption').classList.add('caption__active')
    }

    getData() {
        // this.file = this.form.querySelector('[path]').getAttribute('path'); // ссылка
        let path  = this.form.querySelector('[path]');
        this.file = path ? path.getAttribute('path') : ''


        this.formData = [...new FormData(this.form), ['link', `${document.location.href}`], ['file', this.file]]; // данные формы



        // доп данные
        this.dataValue = this.form.querySelectorAll(`[data-value]`);
        this.dataValue.forEach(item => {
            let name = item.getAttribute('name');
            let value = item.dataset.value;
            if(name === 'dataForm') {
                let arVal = value.split("::");
                arVal.forEach(item => {
                    let subValue = item.split("=");
                    if(subValue[0] === 'target') this.yaTarget = subValue[1];
                    this.formData.push([`${subValue[0]}`, `${subValue[1]}`]);
                });
                return
            }
            this.formData.push([`${name}`, `${value}`]);
        });

        // данные из localStorage
        // let localData = JSON.parse(localStorage.getItem('arrItems'));
        // console.log(this.formData);

        this.submitForm();
    }

    async submitForm() {
        this.url = `phpmailer/send.php`;
        this.body = Object.fromEntries(this.formData);
        console.log(this.body);
        this.body = JSON.stringify(this.body);
        let data = await this.sendRequest();



        this.form.reset();
        alert('Спасибо, мы с Вами свяжемся в ближайшее время!')
        if(this.yaTarget) console.log(this.yaTarget);
    }

    async uploadFiles() {
        const file = this.custom_file.querySelector('input[type="file"]').files[0];
        this.url = `upload.php`
        this.body = new FormData();
        this.body.append('fileUser', file);

        let data = await this.sendRequest();
        const preview = this.custom_file.querySelector('.custom-file__preview');
        preview.innerHTML = `<span path = '${data.file}''>Файл прикреплён (изменить)</span>`;

    }

    sendRequest () {
        return fetch('/form/ajax/' + this.url, {
            method: 'POST',
            body: this.body,
        })
            .then(response => response.json())
            .catch(err => console.log(err))
    }

}

new FormFiles({ options: {}, });


// класс модалки
class Modal {

    constructor(options) {
        this.modalPath = options.path;
        this.options = options.options;
        this.events();
    }

    events() {
        // выпадает автоматом
        if(this.options?.path) {
            this.target = this.options?.path;
            this.animation = 'fadeInUp';
            this.speed = '2000';
            this.loadModal();
        }

        // выпадает при клике
        document.addEventListener('click', function(e){
            this.clickedElement = e.target.closest('[data-path]');

            // open
            if (this.clickedElement) {
                e.preventDefault();
                this.target = this.clickedElement.dataset.path;
                this.window_options  = this.target.split('-');
                let animation = this.clickedElement.dataset.animation;
                let speed = this.clickedElement.dataset.speed;
                this.animation = animation ? animation : 'fadeInUp';
                this.speed = speed ? parseInt(speed) : '300';
                this.before = this.clickedElement.dataset.before;
                if(this.before) this.beforeModal();
                this.loadModal();
            }


        }.bind(this));

        document.addEventListener('click', e => {

            // close
            this.close = e.target.dataset.close
            if (this.close) this.closeModal();
        });

    }



    closeModal() {
        this.windowClose = document.querySelector(`[data-target=${this.close}]`)
        this.windowClose.classList.remove('is-open')
        // setTimeout(() => { this.windowClose.remove(); }, 1000);
    }

    async loadModal() {
        if(!this.options?.inDom && (this.window_options.indexOf( 'dom') === -1)) await this.setHtmlModal(); // if not in dom
        this.openModal();
    }

    async setHtmlModal() {
        this.url = 'modal.php';
        this.body = JSON.stringify(this.target);
        let data = await this.sendRequest();
        let {modal} = data
        document.body.insertAdjacentHTML('beforeend', modal)
    }

    openModal() {
        this.modal = document.querySelector(`[data-target="${this.window_options[0]}"]`); // search modal in dom
        if (!this.modal) return

        this.modalContainer = this.modal.querySelector(`.modal-popup__container`);


        if (this.target === 'load') new Load({ options: { element: this.clickedElement, modal: this.modal, container: this.modalContainer} });




        this.modal.style.setProperty('--transition-time', `${this.speed / 1000}s`); // устанавливаем скорость анимации
        this.modalContainer.classList.add(this.animation); // устанавливаем анимацию

        let open = () => {
            // this.disableScroll();
            this.modal.classList.add('is-open');
            this.modalContainer.classList.add('modal-popup-open');

            setTimeout(() => {
                this.modalContainer.classList.add('animate-open');
            }, 0);
        }

        if(this.options?.timeout) {
            setTimeout(() => {
                open();
            }, this.options.timeout);
        } else {
            open();
        }


    }

    beforeModal() {
        if (this.before === 'one') {
            const item = this.clickedElement.closest('.main-goods-item');
            let title = item.querySelector('.main-goods-item__name').textContent;
            let arrItems = [];
            arrItems.push('title', title);
            let json = JSON.stringify(arrItems);
            localStorage.setItem('arrItems', json);
        }
    }
}

new Modal({});
